import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import find from 'lodash/find'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CustomSaveButton, FormLayout, InfoDialog } from '../..'
import { ROUTES } from '../../../config'
import useDialog from '../../../lib/customHooks/useDialog'
import { useTranslate } from '../../../lib/translate'
import { formatIbanForInput } from '../../../lib/util'
import {
  getBackendCountryDepartmentData,
  getBackendSavingsPlansData,
  getFormCreateDepositPageData,
} from '../../../redux/selectors'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './CreateDepositPage1'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  gridContainer: {
    marginTop: theme.spacing(2),
  },
  copyIcon: {
    fontSize: 17,
    color: '#000',
    opacity: 0.8,
  },
  copySuccessIcon: {
    fontSize: 17,
    color: theme.palette.success.main,
  },
  textField: {
    marginBottom: '1.25rem',
  },
  cardImage: {
    height: '10rem',
    objectFit: 'contain',
  },
}))

const CustomCopyIcon = ({ text = '' }) => {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const copy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setSuccess(true)
      setTimeout(() => setSuccess(false), 500)
    })
  }
  return (
    <IconButton size="small" onClick={copy}>
      {success ? (
        <CheckCircleOutlineIcon className={classes.copySuccessIcon} />
      ) : (
        <FileCopyIcon className={classes.copyIcon} />
      )}
    </IconButton>
  )
}

const CreateDepositPageTwo = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()

  const [isOpen, { openDialog, closeDialog }] = useDialog()

  const countryDepartment = useSelector(getBackendCountryDepartmentData)
  const planId = useSelector(getFormCreateDepositPageData(WORKFLOW_PAGE_1))?.savingsPlanId
  const plan = find(useSelector(getBackendSavingsPlansData), { id: planId })

  return (
    <FormLayout title={translate('deposit.title')} wideLayout showClose isSignedIn>
      <Box mt={3}>
        <Box mb={2}>
          <Typography variant="h6" align="center">
            {translate('deposit.subtitle')}
            <IconButton size="small" onClick={openDialog}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          </Typography>
        </Box>
        <Card>
          <CardContent>
            <Typography variant="body1" className={classes.bold} gutterBottom>
              {translate('deposit.info')}
            </Typography>
            <Typography variant="body2">{translate('deposit.info2')}</Typography>
            <Typography variant="caption" color="textSecondary">
              {translate('deposit.ifDumb')}
            </Typography>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.bold}>{translate('deposit.accountName')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} container>
                <Box flex="1">
                  <Typography>{translate('company.name')}</Typography>
                </Box>
                <CustomCopyIcon text={translate('company.name')} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.bold}>{translate('deposit.bankName')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} container>
                <Box flex="1">
                  <Typography>{countryDepartment?.bank1}</Typography>
                </Box>
                <CustomCopyIcon text={countryDepartment?.bank1} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.bold}>{translate('formFields.iban')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} container>
                <Box flex="1">
                  <Typography>{formatIbanForInput(countryDepartment?.iban1)}</Typography>
                </Box>
                <CustomCopyIcon text={countryDepartment?.iban1} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.bold}>{translate('formFields.bic')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} container>
                <Box flex="1">
                  <Typography>{countryDepartment?.bic1}</Typography>
                </Box>
                <CustomCopyIcon text={countryDepartment?.bic1} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.bold}>{translate('deposit.reference')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} container>
                <Box flex="1">
                  <Typography>{plan?.specialPaymentReference}</Typography>
                </Box>
                <CustomCopyIcon text={plan?.specialPaymentReference} />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography variant="body2" className={classes.bold} paragraph>
                {translate('deposit.min100')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box display="flex" width="100%" justifyContent="space-evenly" mb={5} mt={2}>
        <Button variant="text" onClick={() => history.goBack()}>
          {translate('actions.back')}
        </Button>
        <CustomSaveButton onClick={() => history.push(ROUTES.DASHBOARD)}>
          {translate('actions.finish')}
        </CustomSaveButton>
      </Box>
      <InfoDialog
        open={isOpen}
        onClose={closeDialog}
        title={translate('deposit.dialogTitle')}
        infoText={translate('deposit.dialogText')}
      />
    </FormLayout>
  )
}

export default CreateDepositPageTwo
